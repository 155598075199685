"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEmbedCode = void 0;
const constants_1 = require("../constants");
const customVariables_1 = require("./customVariables");
async function getEmbedCode({ flowId, flowName, customLink, customVariables, aspectRatio, queryParams, baseUrl, }) {
    const { sanitize } = (await Promise.resolve().then(() => __importStar(require('dompurify')))).default;
    if (isNaN(aspectRatio))
        aspectRatio = 2 / 3;
    const embedUrl = `${baseUrl}/${flowId}${customLink?.id ? `/${customLink.id}` : ''}?embed${(0, customVariables_1.getCustomValuesQueryString)(queryParams, '&')}`;
    const paddingBottom = `calc(${aspectRatio * 100}% + ${constants_1.BROWSER_BAR_OR_CAPTION_HEIGHT}px)`;
    const title = sanitize((0, customVariables_1.withCustomVariables)(flowName, customVariables, {
        ...customLink?.variables,
        ...(0, customVariables_1.getCustomValuesFromQuery)(queryParams ?? {}),
    })).replace('"', '&quot;');
    return `<div style="position: relative; padding-bottom: ${paddingBottom}; height: 0;"><iframe src="${embedUrl}" title="${title}" frameborder="0" loading="lazy" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;color-scheme: light;"></iframe></div>`;
}
exports.getEmbedCode = getEmbedCode;
